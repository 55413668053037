import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              {/* <a href="mailto:squarehack1@gmail.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a> */}
              
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
              <Large to="/" left="true">
                {t("Application Security")}
              </Large>
              <Large left="true" to="/">
                {t("Software Principles")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
            <Language>{t("Let's Chat")}</Language>
              <LanguageSwitchContainer>
              <LanguageSwitch>
              <SocialLink
                  href="https://wa.me/8178508993?text=Hello%2C%20I%27m%20interested%20in%20your%20services"
                  src="whatsapp.png"
                />
                </LanguageSwitch>
                <LanguageSwitch>
                <SocialLink
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=sh@squarehack.in,squarehack1@gmail.com&su=SquareHack%20Client&body=Hello%2C%20I%27m%20interested%20in%20your%20services"
                  src="email.svg"
                />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/">
                {t("Support Center")}
              </Large>
              <Large left="true" to="/">
                {t("Customer Support")}
              </Large>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>+91-8178508993</Para>
              <Para>squarehack1@gmail.com</Para>
              <Para>sh@squarehack.in</Para>
              <Para>New Delhi</Para>
              <Para>110016 Delhi</Para>
              <Para>India</Para>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
              <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="india.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink>
              <LogoContainer>
                <SvgIcon
                  src="logo1.gif"
                  aria-label="homepage"
                  width="300px" 
                  height="150px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
            <SocialLink
                href="https://www.linkedin.com/in/square-hack-7708a623a"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://www.instagram.com/square_hack/profilecard/?igsh=aGpueTBia3RxYWsz"
                src="instagram.svg"
              />
              <SocialLink
                href="https://x.com/SquareHack"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.facebook.com/SquareHack12"
                src="facebook.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
